/**
 * Custom appsflyer attribution - based on script here:
 * https://support.appsflyer.com/hc/en-us/articles/4415354290321
 *
 * @param link
 * @returns string
 */
export function generateAppsflyerUrl(link) {
    /**
     * Check if user was sent with appsflyer params
     */
    const afParams = sessionStorage.getItem('af_params');
    
    /**
     * User params from redirect or default to current url
     */
    let urlParams;
    if (afParams && afParams !== '/') urlParams = afParams;
    else urlParams = location.href;
    
    /**
     * Function to return a found url param value
     */
    const getParam = getParamFromUrl(urlParams);
    
    /**
     * Create recognised appsflyer params
     */
    let sourceParam = '?pid=';
    let campaignParam = '&c=';
    let partnerParam = '&af_prt=';
    let channelParam = '&af_channel=';
    let customParam = '&af_sub1=';
    let deeplinkParam = '&af_dp=';
    let kwParam = '&af_keywords=';
    
    /**
     * Get media source
     */
    let pidValue;
    if (getParam('pid')) pidValue = getParam('pid');
    else if (getParam('af_pid')) pidValue = getParam('af_pid');
    else if (getParam('utm_source')) pidValue = getParam('utm_source');
    
    /**
     * Get campaign name
     */
    let campaignValue;
    if (getParam('c')) campaignValue = getParam('c');
    else if (getParam('af_c')) campaignValue = getParam('af_c');
    else if (getParam('utm_campaign')) campaignValue = getParam('utm_campaign');
    else campaignValue = 'organic';
    
    /**
     * Get rest of values from url params
     */
    const gclidValue = getParam('gclid'); // google param
    const fbclidValue = getParam('fbclid'); // fb param
    const partnerValue = getParam('af_prt'); // partner param
    const channelValue = getParam('af_channel'); // channel param
    const customValue = getParam('af_sub1'); // other custom param
    const deeplinkValue = getParam('af_dp'); // deep-linking
    const kwValue = getParam('keyword'); // google keywords
    
    /**
     * Assign and append values to params
     */
    campaignParam += campaignValue;
    partnerParam += partnerValue;
    channelParam += channelValue;
    deeplinkParam += deeplinkValue;
    kwParam += kwValue;
    
    /**
     * Google Ads
     */
    if (gclidValue) {
        if (pidValue.toLowerCase() === 'youtube') sourceParam += pidValue;
        else sourceParam += 'google_lp';
        customParam += gclidValue;
    }
    /**
     * Facebook Ads
     */
    else if (fbclidValue) {
        sourceParam += 'facebook';
        customParam += fbclidValue;
    }
    /**
     * Other SRNs and custom networks
     */
    else if (pidValue) {
        sourceParam += pidValue;
        customParam += customValue;
    }
    /**
     * Organic user
     */
    else {
        sourceParam += 'organic';
        customParam += customValue;
    }

    return buildUrl(link, [sourceParam, campaignParam, partnerParam, channelParam, deeplinkParam, customParam, kwParam]);
}

/**
 * Closure function to check url params against given params
 *
 * @param urlParams
 * @returns {(function(*): (null|string|string))|*}
 */
function getParamFromUrl(urlParams) {
    return function(name) {
        name = name.replace(/[[\]]/g, '\\$&');
        const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
        const results = regex.exec(urlParams);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }
}

/**
 * Build appsflyer onelink with all relevant url params
 *
 * @param baseUrl
 * @param params
 * @returns {*}
 */
function buildUrl(baseUrl, params) {
    params.map(param => {
        if (param && !param.includes('null')) baseUrl += param;
    });
    return baseUrl;
}
