import classes from './Footer.module.scss';
import { useRouter } from 'next/router';
import Image from 'next/image';
import Link from 'next/link';
import { configureLogo } from '../../../utils/functions.js';

/**
 * Dynamic Footer
 *
 * @param attributes
 * @returns {JSX.Element|null}
 * @constructor
 */
const Footer = ({ attributes }) => {
    const router = useRouter();

    /**
     * return null needed for react render func
     */
    if (!attributes) return null;

    let { logo, footer_links, social_links } = attributes;

    /**
     * Custom logic before Strapi update
     */
    if (!social_links) {
        footer_links = footer_links?.filter((link) => {
            return link.href.includes('privacy') || link.href.includes('terms');
        });
    }

    return (
        <div className={classes.oFooter}>
            <div className={`container`}>
                <div className={`row`}>
                    <div className={classes.mFooterLeft}>
                        <figure className={`${classes.mLogo}`}>
                            <Link
                                href={{
                                    pathname: configureLogo(router.route, '/home'),
                                    query: `lang=${router.query?.lang || router.defaultLocale}`
                                }}
                            >
                                <a className={classes.mLogoLink}>
                                    <Image
                                        src={logo?.data?.attributes?.url}
                                        alt={`logo`}
                                        width={`96.05`}
                                        height={`27`}
                                        layout="responsive"
                                        className={`${classes.aImage}`}
                                    />
                                </a>
                            </Link>
                        </figure>
                    </div>
                    <div className={classes.mFooterCenter}>
                        <ul className={classes.mFooterMenu}>
                            {footer_links.map((link) => {
                                if (link.href) {
                                    return (
                                        <li key={link.id} className={classes.mLinkItem}>
                                            <Link
                                                href={{
                                                    pathname: link.href,
                                                    query: `lang=${
                                                        router.query?.lang || router.defaultLocale
                                                    }`
                                                }}
                                            >
                                                <a className={`fnt12f`} rel="noopener noreferrer">
                                                    {link.label}
                                                </a>
                                            </Link>
                                        </li>
                                    );
                                }
                            })}
                        </ul>
                    </div>
                    <div className={classes.mFooterRight}>
                        <ul className={`${classes.mSocialMedia} mSocialLinks`}>
                            {social_links?.map((social) => {
                                if (social.href) {
                                    return (
                                        <li
                                            key={social.id}
                                            className={`${classes.mSocialItem} mLinkItem`}
                                        >
                                            <Link href={social.href}>
                                                <a
                                                    target="_blank"
                                                    className={`fnt16s`}
                                                    aria-label={social.name}
                                                    rel="noopener noreferrer"
                                                >
                                                    <Image
                                                        src={`/web${social.temp_image}`}
                                                        alt={social.name}
                                                        width={`30`}
                                                        height={`30`}
                                                        layout="fixed"
                                                        className={`${classes.aImage}`}
                                                    />
                                                </a>
                                            </Link>
                                        </li>
                                    );
                                }
                            })}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;
