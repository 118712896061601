import classes from './DefaultLayout.module.scss';
import { useState, useEffect } from 'react';
import Script from 'next/script';
import Head from 'next/head';
import isWebview from 'is-ua-webview';
import Navbar from '../organisms/navbar/Navbar';
import Footer from '../organisms/footer/Footer';
import { configureApiKey } from '../../utils/netmera';

/**
 * Default layout and setup for Ayoba Website
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const DefaultLayout = (props) => {
    const { navbar, children, footer } = props;

    const [analyticsKey, setAnalyticsKey] = useState('');
    const [isApp, setIsApp] = useState(false);

    useEffect(() => {
        const webview = isWebview(navigator.userAgent);
        setAnalyticsKey(configureApiKey(location.origin));

        if (webview) setIsApp(true);
        else setIsApp(false);
    }, []);

    const netmeraUrl = `https://cdn.netmera-web.com/wsdkjs/${analyticsKey}`;

    return (
        <>
            <Head>
                <title>Ayoba</title>
                <meta
                    name="description"
                    content="Explore Africa's Super-App with free chat, games, music, channels & more"
                />
                <link rel="shortcut icon" href="/web/favicon.ico" />
            </Head>
            <Script
                async
                src="https://www.googletagmanager.com/gtag/js?id=G-1JYXM43X1M"
                crossorigin="anonymous"
            />
            <Script async src="/web/gtm.js" crossorigin="anonymous" />
            <Script
                async
                src="https://www.googletagmanager.com/gtag/js?id=G-G8779EVKC6"
                crossorigin="anonymous"
            />
            <Script async src="/web/gtm2.js" crossorigin="anonymous" />
            {analyticsKey.length && (
                <>
                    <Script id="netmera">var netmera = netmera || [];</Script>
                    <Script src={netmeraUrl} strategy="afterInteractive" async />
                </>
            )}
            <main role="main" className={`${classes.main} main`}>
                {!isApp ? <Navbar {...navbar?.data} /> : null}
                {children}
                {!isApp ? <Footer {...footer?.data} /> : null}
            </main>
        </>
    );
};

export default DefaultLayout;
