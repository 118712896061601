import classes from './Preloader.module.scss';

const Preloader = () => {
    return (
        <div className={classes.mLoader}>
            <div className={classes.aRipple}>
                <div></div>
                <div></div>
            </div>
        </div>
    );
};

export default Preloader;
