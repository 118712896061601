import moment from 'moment-timezone';

/**
 * Netmera Custom Event Trigger as per:
 * https://developer.netmera.com/en/web/events
 *
 * @param eventType
 */
export const fireEvent = (eventType) => {
    window.netmera.sendEvent({
        code: 'wyvhf',
        ea: eventMap[eventType],
        eb: getTimeZoneCountry()
    });
};

/**
 * Event Types
 *
 * @type {{appDownloadGooglePlay: string, appDownloadAppStore: string, appDownloadApk: string}}
 */
const eventMap = {
    appDownloadApk: 'APK Direct Download',
    appDownloadAppStore: 'iOS App Store',
    appDownloadGooglePlay: 'Google Play Store'
};

/**
 * Moment library used to work out timezone and country of user
 *
 * @returns {country}
 */
export const getTimeZoneCountry = () => {
    const { countries, zones } = require('moment-timezone/data/meta/latest.json');

    const timezone = moment.tz.guess();
    const timeZoneToCountry = {};

    Object.keys(zones).forEach((zone) => {
        timeZoneToCountry[zone] = countries[zones[zone].countries[0]].name;
    });

    return timeZoneToCountry[timezone];
};

/**
 * Function to configure API key as per environment
 *
 * @param domain
 * @returns {string}
 */
export const configureApiKey = (domain) => {
    if (domain === 'https://ayoba.me' || domain === 'https://www.ayoba.me') return PROD_API_KEY;
    else return DEV_API_KEY;
};

/**
 * API keys that are exposed in the browser by Netmera
 *
 * @type {string}
 */
const DEV_API_KEY = 'ZNIrCJVQ5OvVVaQNrgrGUI1akqu_8smB0pLZG6tOTiRGQ2LA9iNf6g';
const PROD_API_KEY = 'Vdq_TM0tiu9FEYFItC_aKnNmAP9aCCsqIu3RpHThHu_4W98Nt5Bytw';
