import { useState, useEffect } from 'react';
import { useRouter } from 'next/router';

export const useCheckLanguage = () => {
    const router = useRouter();
    const [language, setLanguage] = useState('');

    useEffect(() => {
        if (router.query?.lang === 'ar') setLanguage('arabic');
    }, [router]);

    return language;
};

export const useTextDirection = () => {
    const router = useRouter();
    const [textDirection, setTextDirection] = useState('');

    useEffect(() => {
        if (router.query?.lang === 'ar') setTextDirection('text_rtl');
        else setTextDirection('');
    }, [router]);

    return textDirection;
};
