import classes from './Navbar.module.scss';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import Image from 'next/image';
import Link from 'next/link';
import BurgerMenu from '../../molecules/burgerMenu/BurgerMenu';
import MenuItem from '../../molecules/menuItem/MenuItem';
import { configureLogo, configureQuery } from '../../../utils/functions.js';
import { generateAppsflyerUrl } from '../../../utils/appsflyer';
import { fireEvent } from '../../../utils/netmera';

/**
 * Dynamic Navbar
 *
 * @param attributes
 * @returns {JSX.Element|null}
 * @constructor
 */
const Navbar = ({ attributes }) => {
    const router = useRouter();
    const [scrollY, setScrollY] = useState(0);
    const [isActive, setIsActive] = useState(false);
    const [dropdown, setDropdown] = useState(false);
    const [pageLoad, setPageLoad] = useState(false);

    const handleToggle = () => setIsActive(!isActive);
    const handleScroll = () => setScrollY(window.scrollY);

    useEffect(() => {
        handleScroll();
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [router]);

    useEffect(() => {
        setPageLoad(true);
    }, []);

    if (!attributes) return null;

    const { logo, menu_options, download_button, language_options } = attributes;
    const ayobaLogo = logo?.image?.data?.attributes?.url;
    const langHeader = logo?.name;

    return (
        <div
            className={`${classes.oHeader} oHeader ${
                scrollY > 100 ? `${classes.fixed} fixed` : `${classes.top}`
            }`}
        >
            <div className={`${classes.oContainer} container`}>
                {pageLoad && download_button?.url?.length && (
                    <div className={classes.mCtaWrapper}>
                        <Link href={generateAppsflyerUrl(download_button.url)}>
                            <a
                                className={`${classes.mDownloadCta}`}
                                target="_parent"
                                rel="noopener"
                                onClick={() => fireEvent('appDownloadApk')}
                            >
                                <span className={`${classes.aLinkText} fnt14f`}>
                                    {download_button.label}
                                </span>
                            </a>
                        </Link>
                    </div>
                )}

                <div className={`${classes.oHeaderWrapper} oHeaderWrapper`}>
                    <span onClick={handleToggle}>
                        <BurgerMenu handleToggle={handleToggle} isActive={isActive} />
                    </span>
                    <figure className={`${classes.mSiteLogo} mSiteLogo`}>
                        <Link
                            href={{
                                pathname: configureLogo(router.route, '/home'),
                                query: `lang=${router.query?.lang || router.defaultLocale}`
                            }}
                        >
                            <a className={classes.mLogoLink}>
                                {ayobaLogo ? (
                                    <Image
                                        src={ayobaLogo}
                                        alt={`ayoba-logo`}
                                        width={`108`}
                                        height={`32`}
                                        layout="responsive"
                                    />
                                ) : null}
                            </a>
                        </Link>
                    </figure>
                </div>
                <nav
                    className={`${classes.oMainNav} oMainNav ${
                        isActive ? `${classes.navOpen}` : `${classes.navClosed}`
                    }`}
                >
                    <ul className={classes.oNavList}>
                        {menu_options?.map((item, index) => (
                            <li className={classes.menuItemWrapper} key={index}>
                                <MenuItem
                                    handleToggle={handleToggle}
                                    item={item}
                                    router={router}
                                    depthLevel={0}
                                />
                            </li>
                        ))}
                    </ul>
                    <div className={classes.mLangSelect}>
                        <button
                            className={`${classes.mLangBtn} mLangBtn`}
                            aria-label="Select language"
                            aria-expanded={dropdown ? 'true' : 'false'}
                            onClick={() => setDropdown((prev) => !prev)}
                        >
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className={classes.aGlobe}
                            >
                                <circle cx="12" cy="12" r="10" fill="#29B2FF" fillOpacity="0.4" />
                                <path
                                    d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                                    stroke="#0067A3"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M2 12H22"
                                    stroke="#0067A3"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M12 2C14.5013 4.73835 15.9228 8.29203 16 12C15.9228 15.708 14.5013 19.2616 12 22C9.49872 19.2616 8.07725 15.708 8 12C8.07725 8.29203 9.49872 4.73835 12 2V2Z"
                                    stroke="#0067A3"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                            <span className={`${classes.mButtonText} fnt16r`}>{langHeader}</span>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className={classes.aCarret}
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M19 9l-7 7-7-7"
                                />
                            </svg>
                        </button>
                        <ul className={`${classes.mLangDropdown} mLangDropdown`}>
                            {language_options?.map((option) => (
                                <li
                                    key={option.country_code}
                                    className={`mLinkItem ${classes.mLinkItem} ${
                                        dropdown ? '' : `${classes.langShow}`
                                    }`}
                                >
                                    <Link
                                        href={{
                                            pathname: router.pathname,
                                            query: configureQuery(option, router)
                                        }}
                                    >
                                        <a
                                            className={`${
                                                router.query?.lang === option.country_code
                                                    ? `${classes.isActive} isActive`
                                                    : ''
                                            } ${classes.mLink} mNavLink`}
                                            rel="noopener"
                                        >
                                            <span
                                                className={`${classes.aLinkText} aLinkText fnt18f`}
                                                onClick={handleToggle}
                                            >
                                                <svg
                                                    width="14"
                                                    height="14"
                                                    viewBox="0 0 14 14"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className={`${classes.aIconActive} aIconActive`}
                                                >
                                                    <path
                                                        d="M11.6673 3.5L5.25065 9.91667L2.33398 7"
                                                        stroke="#44DA4E"
                                                        strokeWidth="1.5"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                </svg>
                                                <span>{option.label}</span>
                                            </span>
                                            <figure className={`${classes.aLinkImage} aLinkImage`}>
                                                <Image
                                                    src={`/web${option.icon_path}`}
                                                    alt={`flag`}
                                                    width={`12`}
                                                    height={`13`}
                                                    layout="fixed"
                                                />
                                            </figure>
                                        </a>
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                </nav>
                <style global jsx>{`
                    .oSubNav .mNavItem:after {
                        transform: translateX(0);
                        right: 0;
                        left: auto;
                        bottom: 3px;
                    }
                    .oSubNav .mNavItem {
                        margin-top: 18px;
                    }
                    .oSubNav .mNavItem:first-child {
                        margin-top: 23px;
                    }
                    .oSubNav .mNavItem:last-child {
                        margin-bottom: 23px;
                    }
                    .oSubNav .mNavItem .aLink {
                        text-align: right;
                        padding: 0 0 10px 0;
                    }
                `}</style>
            </div>
        </div>
    );
};

export default Navbar;
