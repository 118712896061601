import months from './data/months.json';

export function configureLogo(route, defaultRoute) {
    if (route === '/landing-page') return '/landing-page';
    return defaultRoute;
}

export function configureAnchor(anchor, router) {
    return `${router.pathname}?lang=${router.query?.lang || router.defaultLocale}${anchor}`;
}

export function configureQuery(option, router) {
    const query = { lang: option.country_code };

    if (router.asPath.includes('articles') || router.asPath.includes('competitions')) {
        let path;
        if (router.query?.lang) {
            const index = router.asPath.indexOf('?');
            path = router.asPath.slice(0, index).split('/');
        } else {
            path = router.asPath.split('/');
        }
        query.slug = path[2];
    }
    return query;
}

export function formatDate(date) {
    const dateSplit = date.split('-');
    const year = dateSplit[0];
    const month = months[parseInt(dateSplit[1]) - 1];
    let day = parseInt(dateSplit[2]);
    switch (day) {
        case 1:
        case 21:
        case 31:
            day += 'st';
            break;
        case 2:
        case 22:
            day += 'nd';
            break;
        case 3:
        case 23:
            day += 'rd';
            break;
        default:
            day += 'th';
    }
    return day + ' ' + month + ' ' + year;
}

export function getLocale(router) {
    const locales = router.locales;
    let lang;

    for (let i = 0; i < locales.length; i++) {
        if (navigator.language.includes(locales[i])) {
            lang = locales[i];
            break;
        }
    }
    if (lang) return lang;
    else return router.defaultLocale;
}

export function getTimezone(areaCode, countryCode) {
    for (let i = 0; i < areaCode.length; i++) {
        if (countryCode === areaCode[i].area_code || countryCode.includes(areaCode[i].name)) {
            return areaCode[i].timezone;
        }
    }
}

export function formatLocalNumber(phone) {
    if (phone.charAt(0) === '0') return phone.slice(1);
    else return phone;
}
