import classes from './Dropdown.module.scss';
import MenuItem from '../menuItem/MenuItem';

const Dropdown = ({ submenu, router, dropdown, depthLevel, handleToggle }) => {
    depthLevel = depthLevel + 1;

    const dropdownClass = depthLevel > 1 ? `${classes.sideDropdown}` : `${classes.hasDropdown}`;

    return (
        <ul
            onClick={handleToggle}
            className={`${classes.oSubNav} oSubNav ${dropdownClass} ${
                dropdown ? `${classes.menuShow}` : ''
            }`}
        >
            {submenu.map((item, index) => (
                <MenuItem item={item} router={router} depthLevel={depthLevel} key={index} />
            ))}
        </ul>
    );
};

export default Dropdown;
