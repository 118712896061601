import '../../assets/scss/.base/bootstrap-reboot.min.css';
import '../../assets/scss/.base/bootstrap-grid.min.css';
import '../../assets/scss/.base/slick.css';
import '../styles/globals.scss';
import '../styles/toast.scss';

import Router from 'next/router';
import { useState, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import { DefaultLayout as Layout } from '../components/templates';
import Preloader from '../components/molecules/preloader/Preloader';
import { useTextDirection } from '../utils/useCheckLanguage';

const App = ({ Component, pageProps }) => {
    const { events } = Router;
    const { data } = pageProps;

    const [loading, setLoading] = useState(false);

    const start = () => setLoading(true);
    const end = () => setLoading(false);

    const text_rtl = useTextDirection();

    useEffect(() => {
        events.on('routeChangeStart', start);
        events.on('routeChangeComplete', end);
        events.on('routeChangeError', end);

        return () => {
            events.off('routeChangeStart', start);
            events.off('routeChangeComplete', end);
            events.off('routeChangeError', end);
        };
    }, [events]);

    return (
        <Layout {...data}>
            {loading ? (
                <Preloader />
            ) : (
                <div className={`oMain ${text_rtl}`}>
                    <ToastContainer />
                    <Component {...pageProps} />
                </div>
            )}
        </Layout>
    );
};

export default App;
