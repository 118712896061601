import { useState, useEffect, useRef } from 'react';
import classes from './MenuItem.module.scss';
import Link from 'next/link';
import Dropdown from '../dropDown/Dropdown';

const MenuItem = ({ handleToggle, item, router, depthLevel }) => {
    const [dropdown, setDropdown] = useState(false);
    let ref = useRef();

    useEffect(() => {
        const handler = (event) => {
            if (dropdown && ref.current && !ref.current.contains(event.target)) {
                setDropdown(false);
            }
        };
        document.addEventListener('mousedown', handler);
        document.addEventListener('touchstart', handler);

        return () => {
            document.removeEventListener('mousedown', handler);
            document.removeEventListener('touchstart', handler);
        };
    }, [dropdown]);

    const onMouseEnter = () => {
        window.innerWidth > 991 && setDropdown(true);
    };

    const onMouseLeave = () => {
        window.innerWidth > 991 && setDropdown(false);
    };

    const isActive = (path) => {
        if (router.pathname === path.href) return 'isActive';
        else if (
            (router.pathname === '/support' || router.pathname === '/faqs') &&
            path.label === 'Help'
        )
            return 'isActive';
        else if (
            (router.pathname === '/about-us' || router.pathname === '/careers') &&
            path.label === 'About us'
        )
            return 'isActive';
        else '';
    };

    return (
        <span
            className={`${classes.mNavItem} ${classes[`${isActive(item)}`]} mNavItem`}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            ref={ref}
        >
            {item.dropdown_option ? (
                <>
                    <button
                        className={`${classes.aLink} ${classes.hasDropdown} aLink fnt16r`}
                        aria-haspopup="menu"
                        aria-expanded={dropdown ? 'true' : 'false'}
                        onClick={() => setDropdown((prev) => !prev)}
                    >
                        {item.label}
                        {depthLevel > 0 ? (
                            <span className={`${classes.aCarret} ${classes.toRight}`} />
                        ) : (
                            <span className={classes.aCarret} />
                        )}
                    </button>
                    <Dropdown
                        submenu={item.dropdown_option}
                        router={router}
                        dropdown={dropdown}
                        depthLevel={depthLevel}
                        handleToggle={handleToggle}
                    />
                </>
            ) : (
                <Link
                    href={{
                        pathname: item.href,
                        query: `lang=${router?.query?.lang || router.defaultLocale}`
                    }}
                >
                    <a className={`${classes.aLink} aLink fnt16r`} onClick={handleToggle}>
                        {item.label}
                    </a>
                </Link>
            )}
        </span>
    );
};

export default MenuItem;
